import React, {useEffect} from "react";
import styles from '../css/PartList.module.scss';
import { animated , useSpring } from '@react-spring/web'
import {useScrollPagePresent} from "../hooks/useScrollPersent";

import { Scrollbar, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

const partList = [
    {name: 'AR 眼镜', imgUrl: 'glass.svg', width: 360, height: 239},
    {name: '用户指南', imgUrl: 'userGuide.svg', width: 169, height: 224},
    {name: '数据线', imgUrl: 'typec.svg', width: 169, height: 224},
    {name: '电池充电器', imgUrl: 'charge.svg', width: 187, height: 117},
    {name: '电池', imgUrl: 'buttery.svg', width: 190, height: 53},
    {name: '包装盒', imgUrl: 'box.svg', width: 172, height: 100},
]

const Screen = React.forwardRef((props,ref)=>{
  const [topStyles, topBoxApi] = useSpring(() => ({
    opacity: 0,
    y: 0,
  }))

  const { process } = useScrollPagePresent({
    ref: ref,
    pageIndex: props.pageNow,
  })

  useEffect(() => {
      if(process > 0 && process < 0.2){
          topBoxApi.start({ opacity: process * 4, y: -100 * process })
      }else if(process> 0.2 && process < 1){
          topBoxApi.start({ opacity: Math.min(process+0.7, 1), y: -40 * Math.min(process+0.6, 1) })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process]);

  return (
      <div className={styles.section}>
          <animated.div className={styles.title} style={topStyles}>
              <div>JINHE AR GLASS</div>
              <div>配件清单</div>
          </animated.div>
          <div className={styles.content}>
              <Swiper
                  modules={[Autoplay, Scrollbar]}
                  autoplay={{delay: 0, disableOnInteraction: false}} // 设置自动播放延迟时间和交互行为
                  spaceBetween={12}
                  slidesPerView={4}
                  loop
                  scrollbar={{draggable: true, hide: true}}
                  direction={"horizontal"}
                  freeMode
                  speed={4000}
              >
                  {
                      partList.map((item, index) => {
                          return (
                              <SwiperSlide key={index}>
                                  <div className={styles.cardItem}>
                                      <div className={styles.imgContainer}>
                                          <img className={styles.img}
                                              src={require(`../assets/partList/${item.imgUrl}`)}
                                               alt=""
                                               style={{width: item.width, height: item.height}}
                                          />
                                      </div>
                                      <div className={styles.name}>{item.name}</div>
                                  </div>
                              </SwiperSlide>
                          )
                      })
                  }
              </Swiper>
          </div>
      </div>
  );
})

export default Screen;
