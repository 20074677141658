import React, {useEffect} from "react";
import styles from '../css/ProductPresent.module.scss';
import { animated , useSpring} from '@react-spring/web'
import {useScrollPagePresent} from "../hooks/useScrollPersent";

const Screen = React.forwardRef((props,ref)=>{
  const [topStyles, topBoxApi] = useSpring(() => ({
    opacity: 0,
    y: 0,
  }))
  const [explainStyles, explainBoxApi] = useSpring(() => ({
    opacity: 0,
    x: 0,
  }))
  const [lineStyles, lineBoxApi] = useSpring(() => ({
        width: 0
    }))

  const [explain2Styles, explain2BoxApi] = useSpring(() => ({
    opacity: 0,
    x: 0,
  }))
  const [line2Styles, line2BoxApi] = useSpring(() => ({
      width: 0,
  }))
  const [explain3Styles, explain3BoxApi] = useSpring(() => ({
    opacity: 0,
    x: 0,
  }))
    const [line3Styles, line3BoxApi] = useSpring(() => ({
        width: 0,
    }))

  const { process } = useScrollPagePresent({
    ref: ref,
    pageIndex: props.pageNow,
  })

  useEffect(() => {
      if(process > 0 && process < 0.1){
          topBoxApi.start({ opacity: process+0.1 , y: -60 * (process+0.1) })
      }else if(process > 0.1 && process < 0.3){
          topBoxApi.start({ opacity: Math.min(process+0.7, 1) , y: -60 * Math.min((process+0.7), 1) })
      }
      // 第一部分
      if(process > 0 && process <0.1){
          lineBoxApi.start({ width: 0 })
      }else if(process > 0.1 && process < 0.24){
          lineBoxApi.start({ width: 120 * process })
      }else if(process > 0.24 && process < 0.3){
        let res = Math.min(process+0.6, 1)
        explainBoxApi.start({ opacity: res , x: 100 *  res })
        lineBoxApi.start({ width: 120 *  res })
      }
    // 第二部分
    else if(process > 0.3 && process < 0.6){
        let res = Math.min(process+0.4, 1)
        if(process > 0.3 && process < 0.4){
            line2BoxApi.start({ width: 120 *  (process-0.3) })
        }else{
            line2BoxApi.start({ width: 120 *  res })
        }
        explain2BoxApi.start({ opacity: res , x:0, y: -100 *  res })
    }
    // 第三部分
    else if(process > 0.6 && process < 1){
          let res = Math.min(process+0.2, 1)
          if(process > 0.6 && process < 0.7){
              line3BoxApi.start({ width: 120 *  (process-0.6) })
          }else{
              line3BoxApi.start({ width: 120 *  res })
          }
          explain3BoxApi.start({ opacity: process , x:-100 *  process })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process]);

  return (
      <div className={styles.section}>
          <div className={styles.bg}></div>
          <div className={styles.content}>
              <animated.div className={styles.top} style={topStyles}>
                  <div className={styles.title}>JINHE AR GLASS</div>
                  <div className={styles.title}>产品介绍</div>
              </animated.div>
              <animated.div className={styles.imgBox}>
                  <img className={styles.img} src={[require('../assets/fullEye.png')]} alt=""/>

                  <animated.div className={styles.line1} style={lineStyles}></animated.div>
                  <animated.div className={styles.explain} style={explainStyles}>
                      <div className={styles.explainTitle}>物理按键</div>
                      <div className={styles.explainContent}>
                          <div className={styles.explainContentItem}>支持旋钮切换/支持返回</div>
                          <div className={styles.explainContentItem}>音量调节按键/开关机按键</div>
                          <div className={styles.explainContentItem}>预留传统操作方式</div>
                      </div>
                  </animated.div>

                  <animated.div className={styles.line2} style={line2Styles}></animated.div>
                  <animated.div className={styles.explain2} style={explain2Styles}>
                    <div className={styles.explainTitle}>光机</div>
                      <div className={styles.explainContent}>
                        <div className={styles.explainContentItem}>超薄10.4mm钻石棱镜自由曲面光学厚度</div>
                        <div className={styles.explainContentItem}>超大可视角    抗强光下不受外界干扰</div>
                        <div className={styles.explainContentItem}>贴合田间强光场景</div>
                    </div>
                  </animated.div>

                  <animated.div className={styles.line3} style={line3Styles}></animated.div>
                  <animated.div className={styles.explain3} style={explain3Styles}>
                    <div className={styles.explainTitle}>主摄</div>
                    <div className={styles.explainContent}>
                      <div className={styles.explainContentItem}>4800万高清摄像头</div>
                      <div className={styles.explainContentItem}>满足2mm小虫体拍摄需求</div>
                    </div>
                  </animated.div>

  
              </animated.div>
          </div>

          <div className={styles.part3}>
              <div className={styles.part3Container}/>
          </div>
      </div>

  );
})

export default Screen;
