import React, {useEffect} from "react";
import styles from '../css/UserCase.module.scss';
import {animated, useSpring} from '@react-spring/web'
import userCaseFont from '../assets/userCaseFont.png'
import IconAddress from '../assets/userCase/address.svg'
import {useScrollPagePresent} from "../hooks/useScrollPersent";

import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const cardList = [
    [
        {imgUrl:'1.png', address: '安徽·南陵'},
        {imgUrl:'2.png', address: '安徽·南陵'},
        {imgUrl:'3.png', address: '安徽·芜湖'},
        {imgUrl:'4.png', address: '江苏·泰州'},
        {imgUrl:'5.png', address: '陕西·陈仓'},
        {imgUrl:'6.png', address: '江苏·泗洪'},
        {imgUrl:'7.png', address: '陕西·宝鸡'},
    ],
    [
        {imgUrl:'8.png', address: '江苏·盱眙'},
        {imgUrl:'9.png', address: '陕西·陇县'},
        {imgUrl:'10.png', address: '江苏·扬州'},
        {imgUrl:'11.png', address: '江苏·淮阴'},
        {imgUrl:'12.png', address: '陕西·宝鸡'},
        {imgUrl:'13.png', address: '江苏·高淳'},
        {imgUrl:'14.png', address: '江苏·姜堰'},
    ],
    [
        {imgUrl:'15.png', address: '江苏·镇江'},
        {imgUrl:'16.png', address: '陕西·陇县'},
        {imgUrl:'17.png', address: '江苏·泗阳'},
        {imgUrl:'18.png', address: '江苏·镇江'},
        {imgUrl:'19.png', address: '江苏·邗江'},
        {imgUrl:'20.png', address: '江苏·泰州'},
        {imgUrl:'21.png', address: '江苏·邗江'},
    ],
]

const Screen = React.forwardRef((props, ref) => {
    const [topStyles, topBoxApi] = useSpring(() => ({
        opacity: 0,
        y: 0,
    }))

    const {process} = useScrollPagePresent({
        ref: ref,
        pageIndex: props.pageNow,
    })

    useEffect(() => {
        if(process>0 && process<0.1){
            topBoxApi.start({opacity: process, y: -80 * process})
        }else if(process>0.1&& process<0.3){
            topBoxApi.start({opacity: process+0.3, y: -80 * (process+0.3)})
        }else{
            topBoxApi.start({opacity: Math.min(process+0.6, 1), y: -Math.min(80, (process+0.6) * 80)})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [process]);


    return (
        <div className={styles.section}>
            <animated.div className={styles.top}>
                <img className={styles.imgTop} src={userCaseFont} alt=""/>
                <animated.div className={styles.topFont} style={topStyles}>
                    <div className={styles.title}>JINHE AR GLASS</div>
                    <div className={styles.title}>用户案例</div>
                    <div className={styles.subtitle}>
                        <div>解放双手，语音控制的 AR＋AI 田间可移动智能监测调查设备</div>
                        <div> 场景丰富，覆盖一类农作物病虫害，更多功能敬请期待……</div>
                    </div>
                </animated.div>
            </animated.div>
            <div className={styles.content}>
                {
                    cardList.map((item, index) => {
                        return (
                            <div className={styles.cardbox} key={index} >
                                <Swiper
                                    modules={[Autoplay]}
                                    autoplay={{
                                        delay: 0,
                                        reverseDirection: index === 1 ,  // 反向播放
                                        disableOnInteraction: false,
                                        pauseOnMouseEnter: true,// 鼠标移入暂停
                                    }} // 设置自动播放延迟时间和交互行为
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    loop
                                    direction={"horizontal"}
                                    freeMode
                                    speed={5000}
                                >
                                        {
                                            item.map((card, cardIndex) => {
                                                return (
                                                    <SwiperSlide key={cardIndex}>
                                                        <div className={styles.cardItem}>
                                                            <img className={styles.cardImg} src={require(`../assets/userCase/${card.imgUrl}`)} alt=""/>
                                                            <div className={styles.address}>
                                                                <img className={styles.icon} src={IconAddress} alt=""/>
                                                                {card.address}
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                </Swiper>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
})

export default Screen;
