import styles from '../css/firstScreen.module.scss';
import React, {useEffect} from "react";
import {animated, useSpring} from '@react-spring/web'
import {useScrollPagePresent} from "../hooks/useScrollPersent";

const FirstScreen = React.forwardRef((props, ref) => {
    const [textStyles, api] = useSpring(() => ({
        from: {opacity: 0, height: '105%'}, to: {opacity: 1, height: '100%'}, config: {duration: 1200},
    }), [])

    const [videoStyles, videoApi] = useSpring(() => ({
        opacity: 1,
    }))

    const {scrollYProgress} = useScrollPagePresent({
        ref: ref, pageIndex: props.pageNow,
    })

    useEffect(() => {
        if (scrollYProgress !== undefined) {
            api.start({opacity: Math.max(1 - scrollYProgress * 100, 0.01)})
            videoApi.start({opacity: Math.max(1 - scrollYProgress * 100, 0.5)})
        }
    }, [scrollYProgress, videoApi, api]);

    return (<div className={styles.section}>
        <animated.div className={styles.imgBox} style={videoStyles}>
            <img className={styles.imgBox} src={[require("../assets/eye.png")]} alt=''/>
        </animated.div>
        <animated.div className={styles.fontBox} style={textStyles}>
            <div className={styles.title}>田间可移动智能监测调查工具</div>
            <div className={styles.subtitle}>JINHE AR GLASS</div>
        </animated.div>
    </div>)
})

export default FirstScreen