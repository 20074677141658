import styles from '../css/threeScreen.module.scss';
import React, {useEffect} from "react";
import { animated , useSpring} from '@react-spring/web'
import {useScrollPagePresent} from "../hooks/useScrollPersent";

const Screen = React.forwardRef((props,ref)=>{
  const [leftStyles, LeftApi] = useSpring(() => ({
    opacity: 0,
    y:300
  }))
  const [rightStyles, rightApi] = useSpring(() => ({
    opacity: 0,
    x: 0,
  }))

  const { process } = useScrollPagePresent({
    ref: ref,
    pageIndex: props.pageNow,
  })

  useEffect(() => {
    let res = Math.min(process+0.2, 1)
    if(process > 0 && process < 0.6){
      LeftApi.start({ opacity: process , y: (300 - 300 * process) })
      rightApi.start({opacity: process , x: -100 * process })
    }

    if(process > 0.6 && process < 1){
      LeftApi.start({ opacity: res , y: (300 - 300 * res) })
      rightApi.start({opacity: res , x: -100 * res })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process]);


  return ( 
    <div className={styles.section}>
      <animated.div className={styles.imgBox} style={leftStyles}>
        <img className={styles.imgFont} src={[require('../assets/font.png')]} alt=""/>
      </animated.div>

      <animated.div className={styles.eyeBox} style={rightStyles}>
        <img className={styles.imgEye} src={[require('../assets/eye1.png')]} alt=""/>
      </animated.div>
    </div>
   
  );
})
  
export default Screen;
